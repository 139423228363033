<template>
  <v-container
    fill-height
    fluid
  >
    <router-view />
  </v-container>
</template>

<script>
export default {
  name: 'EmptyLayout'
}
</script>

<style lang="scss" scoped></style>
